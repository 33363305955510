<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-xl-6">
      <a-form :form="form" @submit.prevent.native="onSubmit">
        <div class="card">
          <div class="card-header">
            <strong>Request Password Reset</strong>
          </div>
          <div class="card-body">
            <p>Lost your password? Enter the email address associated with your account and we will send you a reset token.</p>
            <a-form-item label="Email Address" :colon="false" required>
              <a-input
                v-decorator="['email', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Email address is required.' }],
                  }]
                }]"
                name="email"
                type="email"
                placeholder="name@company.com"
              />
            </a-form-item>
          </div>
          <div class="card-footer">
            <a-button type="primary" html-type="submit" :loading="loading">
              Request
            </a-button>
            <a-button type="link" @click="$router.push({ name: 'login' })">
              Back to account login
            </a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          await this.$httpd.post('/user/password', {
            _csrf: this.$store.state.csrfToken,
            ...values,
          })
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.form.resetFields()
        this.$message.success('You will receive instructions to reset your password if this email address is registered.')
      })
    },
  },
}
</script>
