var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-9 col-xl-8"},[_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Password Reset")])]),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v("To reset your password, enter a new secure password for your account.")]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('a-form-item',{attrs:{"label":"New Password","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                    validate: [{
                      trigger: 'change',
                      rules: [{ required: true, message: 'Password is required.' }],
                    }, {
                      trigger: 'blur',
                      rules: [{ min: 6, message: 'Password is too short.' }],
                    }]
                  }]),expression:"['password', {\n                    validate: [{\n                      trigger: 'change',\n                      rules: [{ required: true, message: 'Password is required.' }],\n                    }, {\n                      trigger: 'blur',\n                      rules: [{ min: 6, message: 'Password is too short.' }],\n                    }]\n                  }]"}],attrs:{"name":"password","type":"password","placeholder":"Choose a secure password"}})],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('a-form-item',{attrs:{"label":"Confirm New Password","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password_confirm', {
                    validate: [{
                      trigger: 'change',
                      rules: [{ required: true, message: 'Password is required.' }],
                    }, {
                      trigger: 'blur',
                      rules: [{ min: 6, message: 'Password is too short.' }],
                    }]
                  }]),expression:"['password_confirm', {\n                    validate: [{\n                      trigger: 'change',\n                      rules: [{ required: true, message: 'Password is required.' }],\n                    }, {\n                      trigger: 'blur',\n                      rules: [{ min: 6, message: 'Password is too short.' }],\n                    }]\n                  }]"}],attrs:{"name":"password_confirm","type":"password","placeholder":"Enter same password once more"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Change ")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }