<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-xl-8">
      <a-form :form="form" @submit.prevent.native="onSubmit">
        <div class="card">
          <div class="card-header">
            <strong>Password Reset</strong>
          </div>
          <div class="card-body">
            <p>To reset your password, enter a new secure password for your account.</p>
            <div class="row mt-4">
              <div class="col-12 col-lg-6">
                <a-form-item label="New Password" :colon="false" required>
                  <a-input
                    v-decorator="['password', {
                      validate: [{
                        trigger: 'change',
                        rules: [{ required: true, message: 'Password is required.' }],
                      }, {
                        trigger: 'blur',
                        rules: [{ min: 6, message: 'Password is too short.' }],
                      }]
                    }]"
                    name="password"
                    type="password"
                    placeholder="Choose a secure password"
                  />
                </a-form-item>
              </div>
              <div class="col-12 col-lg-6">
                <a-form-item label="Confirm New Password" :colon="false" required>
                  <a-input
                    v-decorator="['password_confirm', {
                      validate: [{
                        trigger: 'change',
                        rules: [{ required: true, message: 'Password is required.' }],
                      }, {
                        trigger: 'blur',
                        rules: [{ min: 6, message: 'Password is too short.' }],
                      }]
                    }]"
                    name="password_confirm"
                    type="password"
                    placeholder="Enter same password once more"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <a-button type="primary" html-type="submit" :loading="loading">
              Change
            </a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$httpd.put(`/user/password/${ this.$route.params.id }`, {
            _csrf: this.$store.state.csrfToken,
            ...values,
          })

          this.$store.commit('user', data)
          this.$router.push({ name: 'index' })

          this.$message.success('Password has been reset.')
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
